import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { content } from '../../constans'
import style from './ChessHeader.module.scss'

export default function ChessHeader() {
    const navigate = useNavigate()

    const lang = useSelector(({changeTextReducer: { lang }}) => lang)
    const mobile = useSelector(({changeTextReducer: { mobile }}) => mobile)
    const text = useSelector(({changeTextReducer: { text }}) => text)

    const [selectPlane, setSelectPlane] = useState("Шахматка")

    const onChoosePage = (e) => {
        if (e === "Шахматка") {
            setSelectPlane("Шахматка")
        }
        if (e === "Планировки") {
            setSelectPlane("Планировки")
        }
        if (e === "Интерактив") {
            setSelectPlane("Интерактив")
        }
    }
    return (
        <>
            <div className={style.wrapChessHeader}>
                <Link className={style.wrapHome} to="/">
                    <div className={style.homeImage}/>
                    <span style={{display: mobile ? "none" : "", fontSize: text ? 26 : 22}}>{content.сhessHeader[lang].main}</span>
                </Link>
                {
                    mobile 
                    ? (
                        <select 
                            value={selectPlane}
                            onChange={(e) => onChoosePage(e.target.value)}
                            className={style.selectPage}
                            style={{fontSize: text ? 26 : 20}}
                        >
                            <option>{content.сhessHeader[lang].chess}</option>
                            <option>{content.сhessHeader[lang].plane}</option>
                            <option>{content.сhessHeader[lang].inter}</option> 
                        </select>
                    )
                    : (
                        <div className={style.chessBtn}>
                            <div className={style.wrapBtn} 
                                style={{backgroundColor: selectPlane === "Шахматка" ? "rgb(71,74,81)" : 'darkgray'}}
                                onClick={() => onChoosePage("Шахматка")}
                                >
                                <span style={{color: 'white', fontSize: text ? 26 : 20}}>{content.сhessHeader[lang].chess}</span>
                            </div>
                            <div className={style.wrapBtn}
                                style={{backgroundColor: selectPlane === "Планировки" ? "rgb(71,74,81)" : 'darkgray'}}
                                onClick={() => onChoosePage("Планировки")}
                            >
                                <span style={{color: 'white', fontSize: text ? 26 : 20}}>{content.сhessHeader[lang].plane}</span>
                            </div>
                            <div className={style.wrapBtn}
                                style={{backgroundColor: selectPlane === "Интерактив" ? "rgb(71,74,81)" : 'darkgray'}}
                                onClick={() => onChoosePage("Интерактив")}
                            >
                                <span style={{color: 'white', fontSize: text ? 26 : 20}}>{content.сhessHeader[lang].inter}</span>
                            </div>
                        </div>
                    )
                }
                
                <div className={style.wrapPhone}>
                    <a href="tel:+375291951821" aria-label="phone" className={style.phone} style={{textDecoration: 'none'}}>
                        <div className={style.phoneImage}/>
                        <span style={{display: mobile ? "none" : "", fontSize: text ? 26 : 22}}>+375(29)195-18-21</span>
                    </a>
                </div>
            </div>
            <div className={style.wrapStatus}>
              <div className={style.status} style={{backgroundColor: 'green'}}><span style={{fontSize: mobile && text ? 16 : !mobile && text ? 20 : !mobile && !text ? 16 : 13}}>{content.сhessHeader[lang].free}</span></div>  
              <div className={style.status} style={{backgroundColor: 'brown'}}><span style={{fontSize: mobile && text ? 16 : !mobile && text ? 20 : !mobile && !text ? 16 : 13}}>{content.сhessHeader[lang].reserv}</span></div>
              <div className={style.status} style={{backgroundColor: '#403A3A'}}><span style={{fontSize: mobile && text ? 16 : !mobile && text ? 20 : !mobile && !text ? 16 : 13}}>{content.сhessHeader[lang].sold}</span></div>
              <div className={style.status} style={{backgroundColor: 'darkgrey'}}><span style={{fontSize: mobile && text ? 16 : !mobile && text ? 20 : !mobile && !text ? 16 : 13}}>{content.сhessHeader[lang].notSale}</span></div>
            </div>
        </>
    )
}
