import React, { useEffect, useLayoutEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from 'react-responsive-carousel'

import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { content } from '../../constans'
import { getChess } from '../../core/actions/restChessActions'
import { isMobile } from '../../core/actions/changeTextAction'

import style from './Main.module.scss'

export default function Main() {
  const dispatch = useDispatch()

  const lang = useSelector(({changeTextReducer: { lang }}) => lang)
  const text = useSelector(({changeTextReducer: { text }}) => text)

  const [mobile, setMobile] = useState(false)
  const [ showPayment, setShowPayment ] = useState(false)
  const [ showInstallment, setShowInstallment ] = useState(false)
  const [ showCredit, setShowCredit ] = useState(false)

  useLayoutEffect(() => {
    const userAgent = navigator.userAgent
    const mobile = /mobile|iphone|ipad|ipod|android|blackberry|mini|windows\sce|palm/i.test(userAgent)
    setMobile(mobile)
    dispatch(isMobile(mobile))
  }, [])

  useEffect(() => {
    dispatch(getChess())
  }, [])

  
  const onGetData = () => {
  
  }
  const onPostData = () => {
    
  }

  return (
    <div className={style.mainWrapper}>
      <Header/>
      <Carousel
        autoPlay={true}
      >
        <div className={style.carouselImgs}>
          <img src={require('./images/home1.jpg')} alt='home1'/>
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home2.jpg')} alt='home2'/>
        </div>
        <div className={style.carouselImgs}>
          <img src={require('./images/home3.jpg')} alt='home3'/>
        </div>
      </Carousel>
      <Link className={style.wrapChooseFlat} to="/chess">
        <div className={style.flatImage}/>
        {content.header[lang].chooseFlat}
      </Link>
      <a name="О_комплексе"></a>
      <div className={style.wrapAdvantages}>
        <span className={style.title}>{content.aboutComplex[lang].title}</span>
        <div className={style.advantages}>
          <div className={style.block}>
            <div className={style.flat1Image}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant1}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 22 : 16}} >{content.aboutComplex[lang].text1}</span>
          </div>
          <div className={style.blockMain}>
            <div className={style.homeImage}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant2}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 22 : 16}}>{content.aboutComplex[lang].text2}</span>
          </div>
          <div className={style.block}>
            <div className={style.lotusImage}/>
            <span className={style.titleAdvant} style={{fontSize: text ? 28 : 22}}>{content.aboutComplex[lang].titleAdvant3}</span>
            <span className={style.textAdvant} style={{fontSize: text ? 22 : 16}}>{content.aboutComplex[lang].text3}</span>
          </div>
        </div>
      </div>
      <div className={style.wrapMap}>
        <span className={style.title}>{content.infrastructure[lang].title}</span>
        <span className={style.text} style={{fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{content.infrastructure[lang].text}</span>
        <span className={style.title} style={{marginTop: 40}}>{content.location[lang].title}</span>
        <span className={style.text} style={{marginBottom: 30, fontSize: text ? 24 : mobile && !text ? 17 : 20}}>{content.location[lang].text}</span>
        <div className={style.map}>
          <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A77db8c2e3ce6154a0a4c75b02b075c3604338e9028742eccb601fd1408ad2676&amp;source=constructor" width="100%" height="100%" frameborder="0">
          </iframe>
        </div>
        
      </div>
      <a name="Условия_оплаты"></a>
      <div className={style.wrapPaymenTerms}>
        <span className={style.title}>{content.paymentTerms[lang].title}</span>
        <div className={style.wrapModule}>
          <div className={showPayment ? style.showModule : style.module}
            onClick={() => setShowPayment(item => !item)}
          > <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule1}</span>
            <span className={style.textModule} style={{display: showPayment ? "flex" : "none", fontSize: text ? 24 : 18}}>
              {content.paymentTerms[lang].textModule1}
            </span>
          </div>
          <div className={showInstallment ? style.showModule : style.module}
            onClick={() => setShowInstallment(item => !item)}
          >
            <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule2}</span>
            <span className={style.textModule} style={{display: showInstallment ? "flex" : "none", fontSize: text ? 24 : 18}}>
              {content.paymentTerms[lang].textModule2}
            </span>
          </div>
          <div className={showCredit ? style.showModule : style.module}
            onClick={() => setShowCredit(item => !item)}
          >
            <span className={style.titleModule} style={{fontSize: text ? 30 : 24}}>{content.paymentTerms[lang].titleModule3}</span>
            <span className={style.textModule} style={{display: showCredit ? "flex" : "none", fontSize: text ? 24 : 18}}>
             {content.paymentTerms[lang].textModule3}
            </span>
          </div>
        </div>
      </div>
      <a name="Контакты"></a>
      <Footer/>
      <div className={style.wrapCreatedPF}>
        {/* <img src={require('./images/site.webp')} style={{marginRight: 10}} width='28' height='25' alt='mobile app'/> */}
        <span>Created by&nbsp;&nbsp;</span>
        <a style={{color: 'black'}} href="https://create-site.by/">https://create-site.by</a>
      </div>

      {/* <div className={style.get}
        onClick={onGetData}
      >Получить</div> */}
      {/* <div className={style.post}
        onClick={onPostData}
      >Отправить</div> */}
    </div>
  )
}
