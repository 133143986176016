import { takeEvery, put, call } from 'redux-saga/effects'
import { GET_CHESS, getChessSuccess, getChessError } from '../actions/restChessActions'
import httpProvider from '../../common/httpProvider'
import { CHESS_URL } from '../../common/api'

function* workerLoader() {
    try {
        const { data } = yield call(httpProvider.get, CHESS_URL)
        yield put(getChessSuccess(data))
      } catch (error) {
        yield put(getChessError(error))
      }
  }

export default function* watcherGetChess() {
  yield takeEvery(GET_CHESS, workerLoader)
}