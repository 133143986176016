export const getChess = (data) => {
    return {
        type: 'GET_CHESS',
        payload: data 
    } 
}
export const GET_CHESS = 'GET_CHESS'

export const getChessSuccess = (data) => {
    return {
        type: 'GET_CHESS_SUCCESS',
        payload: data 
    } 
}
export const getChessError = (data) => {
    return {
        type: 'GET_CHESS_ERROR',
        payload: data 
    } 
}

export const sendData = (data) => {
    return {
        type: 'SEND_DATA',
        payload: data 
    } 
}
export const SEND_DATA = 'SEND_DATA'

export const sendDataSuccess = (data) => {
    return {
        type: 'SEND_DATA_SUCCESS',
        payload: data 
    } 
}
export const sendDataError = (data) => {
    return {
        type: 'SEND_DATA_ERROR',
        payload: data 
    } 
}

export const changeMessageStatus = () => {
    return {
        type: 'CHANGE_MESSAGE_STATUS'
    } 
}