import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Form, Input } from 'antd'

import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy'
import ModalPolicy from '../../wrapers/ModalWrarrer/ModalPolicy'
import ModalSuccess from '../../wrapers/ModalWrarrer/ModalSuccess'
import { content } from '../../constans'
import { sendData, changeMessageStatus } from '../../core/actions/restChessActions'

import style from './Footer.module.scss'

export default function Footer() {
    const dispatch = useDispatch()
    const [form] = Form.useForm()

    const lang = useSelector(({changeTextReducer: { lang }}) => lang)
    const text = useSelector(({changeTextReducer: { text }}) => text)
    const successMessage = useSelector(({restChessReducer: { successMessage }}) => successMessage)

    const [showPolicy, setShowPolicy] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)

    useEffect(() => {
        successMessage ? setShowModalSuccess(true) : setShowModalSuccess(false)
        setTimeout(() => setShowModalSuccess(false), 2000)
        setTimeout(() => dispatch(changeMessageStatus()), 2000)
    }, [successMessage])

    const onSubmit = (e) => {
        dispatch(sendData(e))
        form.resetFields()
    }

    return (
        <div className={style.footer}>
            <span className={style.mainTitle}>{content.footer.contact[lang].title}</span>
            <div className={style.wrapContact}>
                <div className={style.wrapAdress}>
                    <div className={style.wrapTopBlock}>
                        <div className={style.block}>
                            <div className={style.arrowImage}/>
                            <span className={style.title} style={{fontSize: text ? 20 : 14}}>{content.footer.contact[lang].titleAddress}</span>
                            <span className={style.text} style={{fontSize: text ? 24 : 18}}>{content.footer.contact[lang].textAddress}</span>
                        </div>
                        <div className={style.block}>
                            <div className={style.clockImage}/>
                            <span className={style.title} style={{fontSize: text ? 20 : 14}}>{content.footer.contact[lang].titleRegime}</span>
                            <span className={style.text} style={{fontSize: text ? 24 : 18}}>{content.footer.contact[lang].textRegime}</span>
                        </div>
                    </div>
                    <div className={style.wrapBottomBlock}>
                        <div className={style.block}>
                            <div className={style.phoneImage}/>
                            <span className={style.title} style={{fontSize: text ? 20 : 14}}>{content.footer.contact[lang].titlePhone}</span>
                            <span className={style.text} style={{fontSize: text ? 24 : 18}}>+375 (29) 195-18-21</span>
                        </div>
                    </div>
                </div>
                <div className={style.wrapForm}>
                    <span className={style.title} style={{fontSize: text ? 21 : 15}}>{content.footer.mainForm[lang].title}</span>
                    <Form
                        onFinish={(e) => onSubmit(e)}
                        form={form}
                        initialValues={{remember: false}}
                        className={style.form}
                    >
                        <Form.Item 
                            name='name'
                            rules={[{ required: true, message: 'Введите ваше имя' }]}
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)', fontSize: text ? 21 : 16 }}
                                className={style.input}
                                placeholder={content.footer.mainForm[lang].name}
                            />
                        </Form.Item>
                        <Form.Item 
                            name='email'
                            rules={[{ required: true, message: 'Введите ваш email' }]}
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)', fontSize: text ? 21 : 16 }}
                                className={style.input}
                                placeholder='Email'
                            />
                        </Form.Item>
                        <Form.Item 
                            name='phone'
                            rules={[{ required: true, message: 'Введите ваш телефон' }]}
                        >
                            <Input
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)', fontSize: text ? 21 : 16 }}
                                className={style.input}
                                placeholder={content.footer.mainForm[lang].phone}
                            />
                        </Form.Item>
                        <Form.Item 
                            name='comment'
                        >
                            <Input.TextArea
                                style={{ border: 'none', backgroundColor: 'rgb(247,247,247)', fontSize: text ? 21 : 16 }}
                                className={style.textArea}
                                placeholder={content.footer.mainForm[lang].comment}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button 
                                htmlType='submit'
                                className={style.btn}
                                style={{ border: 'none', fontSize: text ? 21 : 16}}
                                >{content.footer.mainForm[lang].send}
                            </Button>
                        </Form.Item>
                        <span className={style.text} style={{fontSize: text ? 20 : 14, color: "gray", textDecoration: "underline", cursor: "pointer", textAlign: "center"}}
                            onClick={() => setShowPolicy(true)}
                        >{content.footer.mainForm[lang].textBottom}</span>
                    </Form>
                </div>
            </div>

            <div className={style.wrapColumns}>
                <div className={style.mainColumn}>
                    <span className={style.title} style={{fontSize: text ? 22 : 16}}>{content.footer.msk[lang].title}</span>
                    <span className={style.text} style={{textDecoration: "none", fontSize: text ? 20 : 14}}>{content.footer.msk[lang].aboutMsk}</span>
                    <div className={style.wrapFooterContact}>
                        <div className={style.contactRow}>
                            <div className={style.homeImage}/>
                            <div className={style.text} style={{paddingTop: 6, fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].titleAddress}: <span className={style.boldText} style={{fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].textAddress}</span></div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.clock1Image}/>
                            <div className={style.text} style={{paddingTop: 4, fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].titleRegime}: <span className={style.boldText} style={{fontSize: text ? 20 : 14}}><br/>{content.footer.contactMsk[lang].textRegime}</span></div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.collImage}/>
                            <div className={style.text} style={{paddingTop: 4, fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].titlePhone}: <span className={style.boldText} style={{fontSize: text ? 20 : 14}}>+375 (29) 195-18-21</span></div>
                        </div>
                        <div className={style.contactRow}>
                            <div className={style.addressImage}/>
                            <div className={style.text} style={{paddingTop: 4, fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].titleLegalAddress}: <span className={style.boldText} style={{fontSize: text ? 20 : 14}}>{content.footer.contactMsk[lang].textLegalAddress}</span></div>
                        </div>
                    </div>
                </div>
                <div className={style.column}>
                    <span className={style.title}  style={{fontSize: text ? 22 : 16}}>{content.footer.developer[lang].title}</span>
                    <a href="https://minskstroy.by/ru" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text} style={{fontSize: text ? 20 : 14}}>{content.footer.developer[lang].site}</span></a>
                    <a href="https://minskstroy.by/ru/rekvizity" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text} style={{fontSize: text ? 20 : 14}}>{content.footer.developer[lang].address}</span></a>
                    <a href="https://minskstroy.by/ru/telefony-sluzhb-i-podrazdeleniy" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text} style={{fontSize: text ? 20 : 14}}>{content.footer.developer[lang].phone}</span></a>
                    <a href="https://minskstroy.by/ru/shema-proezda" style={{textDecoration: 'none', marginBottom: 10}}><span className={style.text} style={{fontSize: text ? 20 : 14}}>{content.footer.developer[lang].scheme}</span></a>
                </div>
                {/* <div className={style.column}>
                    <span className={style.title}>О КОМПЛЕКСЕ</span>
                    <span className={style.text}>Выбрать квартиру</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Об Уютном</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Инфраструктура</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Расположение</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Характеристики домов</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Паркинг</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Новости</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Условия оплаты</span>
                    <span className={style.text}  style={{marginBottom: 14}}>Контакты</span>
                </div> */}
            </div>
            <ModalPolicy showModal={showPolicy}>
                <div className={style.wrapPolicy}>
                <img src={require('./images/close.png')} width="40" height="41" alt='plane' style={{marginRight: 20}}
                    onClick={() => setShowPolicy(false)}
                />
                <PrivacyPolicy/>
                </div>
            </ModalPolicy>
            <ModalSuccess showModal={showModalSuccess}>
                <div className={style.wrapModalSuccess}>
                    {content.footer.mainForm[lang].success}
                    <img src={require('./images/smile.png')} width="58" height="58" alt='smile'/>
                </div>
            </ModalSuccess>
        </div>
    )
}
