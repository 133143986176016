import { all } from 'redux-saga/effects'

import watcherGetChess from './getChessSaga'
import watcherSendData from './sendDataSaga'

export default function* rootSaga() {
    yield all([
        watcherGetChess(),
        watcherSendData()
    ])
}