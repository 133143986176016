export const content = {
    header: {
        ru: {chooseFlat: <span>Выбрать <br/>квартиру</span>, contact: "КОНТАКТЫ", parking: "ПАРКИНГ", paymentTerms: "УСЛОВИЯ ОПЛАТЫ", chooseApartment: "ВЫБРАТЬ КВАРТИРУ", aboutComplex: "О КОМПЛЕКСЕ"},
        br: {chooseFlat: <span>Абярыце <br/>кватэру</span>, contact: "КАНТАКТЫ", parking: "ПАРКIНГ", paymentTerms: "УМОВЫ АПЛАТЫ", chooseApartment: "ВЫБРАЦЬ КВАТЭРУ", aboutComplex: "AБ КОМПЛЕКСЕ"},
        en: {chooseFlat: <span>Select <br/>apartment</span>, contact: "CONTACT", parking: "PARKING", paymentTerms: "PAYMENT TERMS", chooseApartment: "CHOOSE AN APARTMENT", aboutComplex: "ABOUT THE COMPLEX"}
    },
    aboutComplex: {
        ru: {title: "ОТ ИДЕИ К ВОПЛОЩЕНИЮ", titleAdvant1: "Качество внутри и снаружи", titleAdvant2: "Авторская архитектура", titleAdvant3: "Собственная философия", 
                text1: <span>
                    Конструктивная схема здания – монолитный-железобетонный каркас.<br/><br/>
                    При строительстве используются высококачественные материалы и современные технологии строительства.<br/><br/>
                    Все стены возводятся из керамзитобетонных блоков, фасад выполнен с применением декоративной плитки и штукатурки с эффектом кирпичной кладки и утеплен толстым слоем минваты, в квартирах установлены 2- камерные окна из алюминиевого профиля с детскими замками безопастности.
                </span>,
                text2: <span>
                    Каждый проект — это отражение индивидуальности.<br/><br/>  
                    Использование новых технологий, материалов и незаурядных решений, делает здания не просто пространством для жизни или работы, но и произведением искусства, 
                    которые способны вдохновлять и вызывать эмоции.
                </span>,
                text3: <span>
                    Гармоничное сосуществование человека и городской среды.<br/>Удобное расположение в сердце города.<br/><br/>
                    Устойчивое сообщество жильцов.<br/><br/>Доступ к необходимым услугам, таким как магазины, школы, медицинские учреждения.<br/><br/>
                    Философия жилого комплекса в центре города подчеркивает важность создания комфортного, безопасного и вдохновляющего пространства для жизни, чтобы каждый житель чувствовал себя частью целого.
                </span>
            },
        br: {title: "АД ІДЭІ ДА ЎВАСАБЛЕННЯ", titleAdvant1: "Якасць унутры і звонку", titleAdvant2: "Аўтарская архітэктура", titleAdvant3: "Уласная філасофія", 
                text1: <span>
                    Канструктыўная схема будынка - маналітна-жалезабетонны каркас.<br/><br/>
                    Пры будаўніцтве выкарыстоўваюцца высакаякасныя матэрыялы і сучасныя тэхналогіі будаўніцтва.<br/><br/>
                    Усе сцены ўзводзяцца з керамзітабетонных блокаў, фасад выкананы з ужываннем дэкаратыўнай пліткі і тынкоўкі з эфектам цаглянага мура і ўцеплены тоўстым пластом минваты, у кватэрах усталяваны 2-камерныя вокны з алюмініевага профіля з дзіцячымі замкамі бяспекі.
                </span>,
                text2: <span>
                    Кожны праект - гэта адлюстраванне індывідуальнасці.<br/><br/>
                    Выкарыстанне новых тэхналогій, матэрыялаў і выдатных рашэнняў, робіць будынкі не проста прасторай для жыцця ці працы, але і творам мастацтва,
                    якія здольныя натхняць і выклікаць эмоцыі.
                </span>,
                text3: <span>
                    Гарманічнае суіснаванне чалавека і гарадскога асяроддзя.<br/>Зручнае размяшчэнне ў сэрцы горада.<br/><br/>
                    Устойлівае супольнасць жыхароў.<br/><br/>Доступ да неабходных паслуг, такім як крамы, школы, медыцынскія ўстановы.<br/><br/>
                    Філасофія жылога комплексу ў цэнтры горада падкрэслівае важнасць стварэння камфортнай, бяспечнай і натхняльнай прасторы для жыцця, каб кожны жыхар адчуваў сябе часткай цэлага.
                </span>
            },   
        en: {title: "FROM IDEA TO IMPLEMENTATION", titleAdvant1: "Quality inside and out", titleAdvant2: "Author's architecture", titleAdvant3: "Own philosophy", 
                text1: <span>
                    The structural design of the building is a monolithic reinforced concrete frame.<br/><br/>
                    High-quality materials and modern construction technologies are used in construction.<br/><br/>
                    All walls are erected from expanded clay concrete blocks, the facade is made using decorative tiles and plaster with a brick effect and is insulated with a thick layer of mineral wool, the apartments have 2-chamber windows made of aluminum profile with child safety locks.
                </span>,
                text2: <span>
                    Each project is a reflection of individuality.<br/><br/>
                    The use of new technologies, materials and extraordinary solutions makes buildings not just a space for living or working, but also a work of art,
                    which can inspire and evoke emotions.
                </span>,
                text3: <span>
                    Harmonious coexistence of man and the urban environment.<br/>Convenient location in the heart of the city.<br/><br/>
                    Sustainable community of residents.<br/><br/>Access to essential services such as shops, schools, medical facilities.<br/><br/>
                    The philosophy of the residential complex in the city center emphasizes the importance of creating a comfortable, safe and inspiring space for living, so that each resident feels part of the whole.
                </span>
            }
    },
    infrastructure: {
        ru: {title: "ИНФРАСТРУКТУРА", 
                text: <span>
                    Жить в историческом центре города — значит быть частью деловой и    культурной жизни столицы.<br/><br/>
                    Концентрация престижных школ, высших учебных заведений и мест дополнительного и спортивного образования к вашим услугам.<br/><br/>
                    Метро и транспортные развязки позволяют быстро доехать до любого района города. Не нужно тратить драгоценное время на дорогу.  
                    Высокая динамика жизни, масса развлечений, кафе, баров и ресторанов заскучать вам не дадут!<br/>
                    В пешей доступности от вашего дома вы найдете все необходимое для комфортной жизни.
                </span>
            },
            br: {title: "ІНФРАСТРУКТУРА", 
                text: <span>
                    Жыць у гістарычным цэнтры горада - значыць быць часткай дзелавога і культурнага жыцця сталіцы.<br/><br/>
                    Канцэнтрацыя прэстыжных школ, вышэйшых навучальных устаноў і месцаў дадатковай і спартыўнай адукацыі да вашых паслуг.<br/><br/>
                    Метро і транспартныя развязкі дазваляюць хутка даехаць да любога раёна горада. Не трэба марнаваць каштоўны час на дарогу.
                    Высокая дынаміка жыцця, маса забаў, кафэ, бараў і рэстаранаў засумаваць вам не дадуць!<br/>
                    У пешай даступнасці ад вашай хаты вы знойдзеце ўсё неабходнае для камфортнага жыцця.
                </span>
            },
            en: {title: "INFRASTRUCTURE",
                text: <span>
                    Living in the historical center of the city means being part of the business and cultural life of the capital.<br/><br/>
                    Concentration of prestigious schools, higher education institutions and places of additional and sports education at your service.<br/><br/>
                    The metro and transport interchanges allow you to quickly get to any area of ​​the city. No need to waste precious time on the road.
                    High dynamics of life, a lot of entertainment, cafes, bars and restaurants will not let you get bored!<br/>
                    Within walking distance from your home you will find everything you need for a comfortable life.
                </span>
            }
    },
    location: {
        ru: {title: "РАСПОЛОЖЕНИЕ", text: <span>Жилой комплекс расположен в границах улиц Захарова – Андреевская.<br/>Ближайшие станции метро «Пролетарская» и «Площадь победы».</span>},
        br: {title: "РАЗМЯШЧЭННЕ", text: <span>Жылы комплекс размешчаны ў межах вуліц Захарава - Андрэеўская.<br/>Бліжэйшыя станцыі метро «Пралетарская» і «Плошча перамогі».</span>},
        en: {title: "LOCATION", text: <span>The residential complex is located within the boundaries of Zakharova – Andreyevskaya streets.<br/>The nearest metro stations are Proletarskaya and Ploshchad Pobedy.</span>}
    },
    paymentTerms: {
        ru: {title: "УСЛОВИЯ ОПЛАТЫ", titleModule1: "100% оплата", textModule1: "Полная оплата квартиры и машино-места производится в течение 7 (семи) банковских дней.", 
            titleModule2: "Рассрочка", textModule2: <span>Первоначальный взнос при условии оплаты согласно графику платежей по жилому помещению (квартире) составляет 40%; по машино-месту - 40% от стоимости объекта долевого строительства. <br/>Оплата должна быть произведена дольщиком в течение 7 (семи) банковских дней со дня регистрации.</span>, 
            titleModule3: "Кредит", textModule3: <span>Беларусбанк <br/> Белинвестбанк</span>},
        br: {title: "УМОВЫ АПЛАТЫ", titleModule1: "100% аплата", textModule1: "Поўная аплата кватэры і машына-месца праводзіцца на працягу 7 (сямі) банкаўскіх дзён.", 
            titleModule2: "Растэрміноўка", textModule2: <span>Першапачатковы ўзнос пры ўмове аплаты згодна з графікам плацяжоў па жылым памяшканні (кватэры) складае 40%; па машына-месцы - 40 працэнтаў ад кошту аб'екта долевага будаўніцтва.<br/>Аплата павінна быць праведзена дольшчыкам на працягу 7 (сямі) банкаўскіх дзён з дня рэгістрацыі.</span>, 
            titleModule3: "Крэдыт", textModule3: <span>Беларусбанк <br/> Белінвестбанк</span>},
        en: {title: "PAYMENT TERMS", titleModule1: "100% payment", textModule1: "Full payment for the apartment and parking space is made within 7 (seven) banking days.", 
            titleModule2: "Installment plan", textModule2: <span>The initial payment, subject to payment according to the payment schedule, for residential premises (apartment) is 40%; for a parking space - 40% of the cost of the shared construction project.<br/>Payment must be made by the shareholder within 7 (seven) banking days from the date of registration.</span>, 
            titleModule3: "Credit", textModule3: <span>Belarusbank <br/> Belinvestbank</span>}
    },
    сhessHeader: {
        ru: {main: "Главная", chess: "Шахматка", plane: "Планировки", inter: "Интерактив", free: "Свободно", reserv: "Бронь", sold: "Продано", notSale: "Нет в продаже"},
        br: {main: "Галоўная", chess: "Шахматка", plane: "Планіроўкі", inter: "Інтэрактыў", free: "Свабодна", reserv: "Бронь", sold: "Прададзена", notSale: "Няма ў продажы"},
        en: {main: "Main", chess: "Chess", plane: "Layouts", inter: "Interactive", free: "Freely", reserv: "Reservation", sold: "Sold", notSale: "Not for sale"}
    },
    chess: {
        cards: {
            ru: {rooms: "комн.", flat: "Кв.", cost: "Ст-ть", totalArea: "Площадь"},
            br: {rooms: "пак.", flat: "Кв.", cost: "Ст-ть", totalArea: "Плошча"},
            en: {rooms: "rooms", flat: "Apart.", cost: "Cost", totalArea: "Area"},
        },
        card: {
            ru: {rooms: "Количество комнат", flat: "Квартира", cost: "Стоимость", totalArea: "Общая площадь", floor: "Этаж", costM2: "Ст-ть за", 
                textBtn: "Отправить заявку", charact: "Характеристики", kitchenArea: "Площадь кухни", view: "Вид из окна", finishing: "Отделка", add: "Дополнительно",  
                balconyAmount: "Количество лоджий", bathroomAmount: "Количество санузлов", viewTextYard: "Во двор", viewTextStreet: "На улицу", finishingTextR: "Черновая", finishingTextC: "Чистовая"                                  
            },
            br: {rooms: "Колькасць пакояў", flat: "Кватэра", cost: "Кошт", totalArea: "Агульны пляц", floor: "Паверх", costM2: "Ст-ць за",
                textBtn: "Адправіць заяўку", charact: "Характарыстыкі", kitchenArea: "Плошча кухні", view: "Выгляд з акна", finishing: "Аздабленне", add: "Дадаткова",  
                balconyAmount: "Колькасць лоджый", bathroomAmount: "Колькасць санвузлоў", viewTextYard: "У двор", viewTextStreet: "На вуліцу", finishingTextR: "Чарнавая", finishingTextC: "Чыставая"
            },
            en: {rooms: "Number of rooms", flat: "Apartment", cost: "Cost", totalArea: "Total area", floor: "Floor", costM2: "Cost per",
                textBtn: "Submit request", charact: "Characteristics", kitchenArea: "Kitchen area", view: "View from the window", finishing: "Finishing", add: "Additionally",  
                balconyAmount: "Number of loggias", bathroomAmount: "Number of bathrooms", viewTextYard: "Yard", viewTextStreet: "Street", finishingTextR: "Rough",  finishingTextC: "Clean"
            },
        },
        chessForm: {
            ru: {title: "Отправить заявку", quarter: "Модный квартал", flat: "Квартира", name: "Введите имя", email: "Введите", phone: "Введите номер телефона", comment: "Дополнительный комментарий", send: "Отправить", 
                bank: "В кредит/рассрочку", textBottom: "Отправляя данную форму вы соглашаетесь с политикой конфиденциальности", success: <span>Заявка успешно<br/><br/>отправлена!</span>
            },
            br: {title: "Адправіць заяўку", quarter: "Модны квартал", flat: "Кватэра", name: "Увядзіце імя", email: "Увядзіце", phone: "Увядзіце нумар тэлефона", comment: "Дадатковы каментар", send: "Адправіць", 
                bank: "У крэдыт/растэрміноўку", textBottom: "Адпраўляючы дадзеную форму вы згаджаецеся з палітыкай прыватнасці", success: <span>Заяўка паспяхова<br/><br/>адпраўлена!</span>
            },
            en: {title: "Submit request", quarter: "Fashion Quarter", flat: "Apartment", name: "Enter name", email: "Enter", phone: "Enter phone number", comment: "Additional comment", send: "Send", 
                bank: "On credit/in installments", textBottom: "By submitting this form you agree to the privacy policy", success: <span>App successfully<br/><br/>sent!</span>
            },
        }
    },
    footer: {
        contact: {
            ru: {title: "КОНТАКТЫ", titleAddress: "АДРЕС ОФИСА ПРОДАЖ", textAddress: "г. Минск, ул. К. Маркса, 13а, каб.105", 
                titleRegime: "РЕЖИМ РАБОТЫ", textRegime: <span>пн-чт: 8.30 - 17.45, <br/>пт: 8.30 - 16.30, <br/>перерыв: 13.00 - 14.00</span>, titlePhone: "ТЕЛЕФОН"
            },
            br: {title: "КАНТАКТЫ", titleAddress: "АДРАС ОФІСУ ПРОДАЖУ", textAddress: "г. Мінск, вул. K. Маркса, 13а, каб.105", 
                titleRegime: "РЭЖЫМ ПРАЦЫ", textRegime: <span>пн-чц: ​​8.30 - 17.45, <br/>пт: 8.30 - 16.30, <br/>перапынак: 13.00 - 14.00</span>, titlePhone: "ТЭЛЕФОН"},
            en: {title: "CONTACT", titleAddress: "SALES OFFICE ADDRESS", textAddress: "Minsk, st. K. Marksa, 13a, room 105", 
                titleRegime: "WORKING MODE", textRegime: <span>Mon-Thu: 8.30 - 17.45, <br/>Fri: 8.30 - 16.30, <br/>break: 13.00 - 14.00</span>, titlePhone: "PHONE"},
        },
        msk: {
            ru: {title: 'ГПО "МИНСКСТРОЙ"', aboutMsk: "Государственное производственное объединение 'Минскстрой' — это крупнейшая организация строительной отрасли Беларуси, представляющая собой сбалансированную систему организаций различного профиля, что позволяет реализовывать самые сложные проекты в области строительства: от разработки и обоснования инвестирования до ввода объекта в эксплуатацию."},
            br: {title: 'ДВА "МІНСКСБУД"', aboutMsk: "Дзяржаўнае вытворчае аб'яднанне 'Мінскбуд' - гэта буйнейшая арганізацыя будаўнічай галіны Беларусі, якая ўяўляе сабой збалансаваную сістэму арганізацый рознага профілю, што дае магчымасць рэалізоўваць самыя складаныя праекты ў галіне будаўніцтва: ад распрацоўкі і абгрунтавання інвесціравання да ўводу аб'екта ў эксплуатацыю."},
            en: {title: 'GPO "MINSKSTROY"', aboutMsk: "The State Production Association 'Minskstroy' is the largest organization in the construction industry of Belarus, representing a balanced system of organizations of various profiles, which allows implementing the most complex projects in the field of construction: from development and justification of investment to commissioning of the facility."}
        },
        contactMsk: {
            ru: {titleAddress: "Адрес офиса продаж", textAddress: "г. Минск, ул. К. Маркса, 13а, каб.105", 
                titleRegime: "Режим работы", textRegime: <span>пн-чт: 8.30 - 17.45, <br/>пт: 8.30 - 16.30, <br/>перерыв: 13.00 - 14.00</span>, titlePhone: "Телефон",
                titleLegalAddress: "Юридический и почтовый адрес", textLegalAddress: "220030, г.Минск, ул.К.Маркса, 13А"
            },
            br: {titleAddress: "Адрас офiсу продажу", textAddress: "г. Мінск, вул. K. Маркса, 13а, каб.105", 
                titleRegime: "Рэжым працы", textRegime: <span>пн-чц: 8.30 - 17.45, <br/>пт: 8.30 - 16.30, <br/>перапынак: 13.00 - 14.00</span>, titlePhone: "Тэлефон",
                titleLegalAddress: "Юрыдычны і паштовы адрас", textLegalAddress: "220030, г.Мінск, вул.К.Маркса, 13А"
            },
            en: {titleAddress: "Sales office address", textAddress: "Minsk, st. K. Marksa, 13a, room 105", 
                titleRegime: "Working mode", textRegime: <span>Mon-Thu: 8.30 - 17.45, <br/>Fri: 8.30 - 16.30, <br/>break: 13.00 - 14.00</span>, titlePhone: "Phone",
                titleLegalAddress: "Legal and postal address", textLegalAddress: "220030, Minsk, K.Marx St., 13A"
            },
        },
        developer: {
            ru: {title: "О ЗАСТРОЙЩИКЕ", site: "Официальный сайт застройщика", address: "Реквизиты", phone: "Телефон", scheme: "Схема"},
            br: {title: "ПРА ЗАБУДОУШЧЫКА", site: "Афіцыйны сайт забудоўшчыка", address: "Рэквізіты", phone: "Тэлефон", scheme: "Схема"},
            en: {title: "ABOUT THE DEVELOPER", site: "Official website of the developer", address: "Details", phone: "Phone", scheme: "Scheme"},
        },
        mainForm: {
            ru: {title: "Задайте нам любой интересующий вас вопрос в поле комментарий", name: "Имя", phone: "Телефон", comment: "Комментарий", send: "Отправить", textBottom: "Отправляя данную форму вы соглашаетесь с политикой конфиденциальности", success: <span>Заявка успешно<br/><br/>отправлена!</span>},
            br: {title: "Задайце нам любое пытанне ў поле каментар", name: "Iмя", phone: "Тэлефон", comment: "Каментар", send: "Адправіць", textBottom: "Адпраўляючы дадзеную форму вы згаджаецеся з палітыкай прыватнасці", success: <span>Заяўка паспяхова<br/><br/>адпраўлена!</span>},
            en: {title: "Ask us any question you are interested in in the comment field", name: "Name", phone: "Phone", comment: "Comment", send: "Send", textBottom: "By submitting this form you agree to the privacy policy", success: <span>App successfully<br/><br/>sent!</span>},
        },
    },
    privacyPolicy: {
        ru: {title: <span>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ИНТЕРНЕТ-РЕСУРСА.<br/><br/>ГЛОССАРИЙ: <br/><br/></span>,
            text: <span>
                Интернет-ресурс – интернет-сайт, страница интернет-сайта, форум, блог, приложение для мобильного устройства, иной информационный ресурс (его составная часть), размещенный в глобальной компьютерной сети Интернет, посредством которых распространяется компьютерная программа "НОВОСТРОЙКИ";
                <br/><br/>
                Пользователь – юридическое или физическое лицо, которое при посещении Интернет-ресурса предоставило Администрации Интернет-ресурса свои персональные данные и иную информацию;
                <br/><br/>
                Администрация Интернет-ресурса – владелец Интернет-ресурса и (или) его представитель.
                <br/><br/>
                Администрация Интернет-ресурса осуществляет сбор, хранение, обработку и использование информации о Пользователях в целях выполнения обязательств Администрации Интернет-ресурса перед Пользователями в отношении использования Интернет-ресурса и его сервисов.
                <br/><br/>
                Полученную в настоящем соглашении информацию Администрация Интернет-ресурса вправе предоставлять для хранения, обработки и использования агентствам недвижимости, строительным и иным организациям, которым Администрация Интернет-ресурса предоставила по лицензионному соглашению право на ипользование компьютерной программы "НОВОСТРОЙКИ" в рамках реферальной и (или) партнерской программы.
                <br/><br/>
                Состав подлежащей сбору, хранению, обработке и использованию информации:
                <br/><br/>
                1. личные анкетные данные Пользователей (фамилия, имя, отчество, номер мобильного телефона, адрес электронной почты, город (населенный пункт);
                <br/><br/>
                2. иная информация, которую пользователи указали в заявке по собственной инициативе;
                <br/><br/>
                3. данные, получаемые при доступе к Интернет-ресурсу, включающие в себя сведения о технических средствах (устройствах), технологическом взаимодействии с Интернет-ресурсом (в т. ч. IP-адрес, файлы cookie).
                <br/><br/>
                Сбор, хранение, обработка и использование личных данных и иной информации Пользователей осуществляется Администрацией Интернет-ресурса на протяжении всего времени существования Интернет-ресурса с момента предоставления вышеуказанной информации.
                <br/><br/>
                Вся информация Пользователей хранится исключительно на электронных носителях и обрабатывается с использованием автоматизированных систем, за исключением случаев, когда неавтоматизированная обработка таких данных необходима в связи с исполнением требований законодательства.
                <br/><br/>
                Личные данные и иная информация Пользователей не передаются третьим лицам без их согласия, за исключением случаев, предусмотренных законодательством.
                <br/><br/>
                Предоставление личных данных и иной информации Пользователей осуществляется по запросу государственных органов в порядке компетенции, предусмотренной законодательством.
                <br/><br/>
                Личные данные Пользователей подлежат обезличиванию по достижении целей сбора, обработки, распространения, предоставления или в случае утраты необходимости в достижении этих целей, если иной срок их хранения не предусмотрен законодательными актами.
                <br/><br/>
                Администрация Интернет-ресурса принимает технические и организационно-правовые меры в целях обеспечения защиты личных данных и иной информации Пользователей от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий.
                <br/><br/>
                Пользователи вправе обращаться к Администрации Интернет-ресурса по вопросам пользования Интернет-ресурсом и его сервисами, а также по вопросам удаления предоставленной информации до истечения срока ее хранения, обработки и использования. Порядок и сроки рассмотрения обращений Пользователей изложены в соответствующем разделе Интернет-ресурса.
            </span>
        },
        br: {title: <span>ПАЛIТЫКА КАНФІДЭНЦЫЯЛЬНАСЦІ ІНТЭРНЭТ-РЭСУРСАЎ.<br/><br/>ГЛАСАР: <br/><br/></span>, 
            text: <span>
                Інтэрнэт-рэсурс – інтэрнэт-сайт, старонка інтэрнэт-сайта, форум, блог, прыкладанне для мабільнай прылады, іншы інфармацыйны рэсурс (яго складовая частка), размешчаны ў глабальнай камп'ютарнай сетцы Інтэрнэт, з дапамогай якіх распаўсюджваецца камп'ютарная праграма "НАВАБУДЫ";
                <br/><br/>
                Карыстальнік - юрыдычная або фізічная асоба, якая пры наведванні Інтэрнэт-рэсурсу прадаставіла Адміністрацыі Інтэрнэт-рэсурсу свае персанальныя дадзеныя і іншую інфармацыю;
                <br/><br/>
                Адміністрацыя Інтэрнэт-рэсурсу - уладальнік Інтэрнэт-рэсурсу і (або) яго прадстаўнік.
                <br/><br/>
                Адміністрацыя Інтэрнет-рэсурсу ажыццяўляе збор, захоўванне, апрацоўку і выкарыстанне інфармацыі аб Карыстальніках у мэтах выканання абавязацельстваў Адміністрацыі Інтэрнет-рэсурсу перад Карыстальнікамі адносна выкарыстання Інтэрнет-рэсурсу і яго сэрвісаў.
                <br/><br/>
                Атрыманую ў гэтым пагадненні інфармацыю Адміністрацыя Інтэрнет-рэсурсу мае права прадастаўляць для захоўвання, апрацоўкі і выкарыстання агенцтвам нерухомасці, будаўнічым і іншым арганізацыям, якім Адміністрацыя Інтэрнет-рэсурсу прадаставіла па ліцэнзійнай дамове права на карыстанне камп'ютарнай праграмы "НАВАБУДЫ" ў рамках Рэферальнай і (або) партнёрскай. праграмы.
                <br/><br/>
                Склад падлягае збору, захоўванню, апрацоўцы і выкарыстанню інфармацыі:
                <br/><br/>
                1. асабістыя анкетныя дадзеныя Карыстальнікаў (прозвішча, імя, імя па бацьку, нумар мабільнага тэлефона, адрас электроннай пошты, горад (населены пункт);
                <br/><br/>
                2. іншая інфармацыя, якую карыстальнікі паказалі ў заяўцы па ўласнай ініцыятыве;
                <br/><br/>
                3. дадзеныя, якія атрымліваюцца пры доступе да Інтэрнэт-рэсурсу, улучальныя ў сябе звесткі аб тэхнічных сродках (прыладах), тэхналагічным узаемадзеянні з Інтэрнэт-рэсурсам (у т. ч. IP-адрас, файлы cookie).
                <br/><br/>
                Збор, захоўванне, апрацоўка і выкарыстанне асабістых дадзеных і іншай інфармацыі Карыстальнікаў ажыццяўляецца Адміністрацыяй Інтэрнет-рэсурсу на працягу ўсяго часу існавання Інтэрнет-рэсурсу з моманту прадастаўлення вышэйпаказанай інфармацыі.
                <br/><br/>
                Уся інфармацыя Карыстальнікаў захоўваецца выключна на электронных носьбітах і апрацоўваецца з выкарыстаннем аўтаматызаваных сістэм, за выключэннем выпадкаў, калі неаўтаматызаваная апрацоўка такіх даных неабходна ў сувязі з выкананнем патрабаванняў заканадаўства.
                <br/><br/>
                Асабістыя даныя і іншая інфармацыя Карыстальнікаў не перадаюцца трэцім асобам без іх згоды, за выключэннем выпадкаў, прадугледжаных заканадаўствам.
                <br/><br/>
                Прадастаўленне асабістых даных і іншай інфармацыі Карыстальнікаў ажыццяўляецца па запыце дзяржаўных органаў у парадку кампетэнцыі, прадугледжанай заканадаўствам.
                <br/><br/>
                Асабістыя даныя Карыстальнікаў падлягаюць абязлічванню па дасягненні мэт збору, апрацоўкі, распаўсюджвання, прадастаўлення або ў выпадку страты неабходнасці ў дасягненні гэтых мэт, калі іншы тэрмін іх захоўвання не прадугледжаны заканадаўчымі актамі.
                <br/><br/>
                Адміністрацыя Інтэрнэт-рэсурсу прымае тэхнічныя і арганізацыйна-прававыя меры ў мэтах забеспячэння абароны асабістых дадзеных і іншай інфармацыі Карыстальнікаў ад неправамернага ці выпадковага доступу да іх, знішчэння, змены, блакавання, капіравання, распаўсюджвання, а таксама ад іншых неправамерных дзеянняў.
                <br/><br/>
                Карыстальнікі маюць права звяртацца да Адміністрацыі Інтэрнэт-рэсурса па пытаннях карыстання Інтэрнэт-рэсурсам і яго сэрвісамі, а таксама па пытаннях выдалення прадстаўленай інфармацыі да заканчэння тэрміна яе захоўвання, апрацоўкі і выкарыстання. Парадак і тэрміны разгляду зваротаў Карыстальнікаў выкладзены ў адпаведным раздзеле Інтэрнет-рэсурсу.
            </span>},
        en: {title: <span>INTERNET RESOURCE PRIVACY POLICY.<br/><br/>GLOSSARY: <br/><br/></span>, 
            text: <span>
                Internet resource – an Internet site, a page of an Internet site, a forum, a blog, an application for a mobile device, or another information resource (its component) located on the global computer network Internet, through which the computer program "NEWS" is distributed;
                <br/><br/>
                User – a legal entity or an individual who, when visiting an Internet resource, provided the Internet resource Administration with their personal data and other information;
                <br/><br/>
                Internet resource Administration – the owner of the Internet resource and (or) their representative.
                <br/><br/>
                The Internet resource Administration collects, stores, processes, and uses information about Users in order to fulfill the obligations of the Internet resource Administration to Users regarding the use of the Internet resource and its services.
                <br/><br/>
                The Internet resource Administration has the right to provide the information received in this agreement for storage, processing and use to real estate agencies, construction and other organizations to which the Internet resource Administration has granted, under a license agreement, the right to use the computer program "NEW BUILDINGS" within the framework of a referral and (or) affiliate program.
                <br/><br/>
                The composition of the information subject to collection, storage, processing and use:
                <br/><br/>
                1. personal profile data of Users (last name, first name, patronymic, mobile phone number, email address, city (town);
                <br/><br/>
                2. other information that users indicated in the application on their own initiative;
                <br/><br/>
                3. data obtained when accessing the Internet resource, including information about technical means (devices), technological interaction with the Internet resource (including IP address, cookies).
                <br/><br/>
                The collection, storage, processing and use of personal data and other information of Users is carried out by the Administration of the Internet resource throughout the entire period of existence of the Internet resource from the moment the above information is provided.
                <br/><br/>
                All information of Users is stored exclusively on electronic media and processed using automated systems, except for cases when non-automated processing of such data is necessary in connection with the implementation of legal requirements.
                <br/><br/>
                Personal data and other information of Users are not transferred to third parties without their consent, except in cases stipulated by law.
                <br/><br/>
                Provision of personal data and other information of Users is carried out at the request of state bodies in the order of competence stipulated by law.
                <br/><br/>
                Personal data of Users are subject to anonymization upon achievement of the purposes of collection, processing, distribution, provision or in case of loss of need to achieve these purposes, unless another storage period is stipulated by legislative acts.
                <br/><br/>
                The Administration of the Internet resource takes technical and organizational-legal measures in order to ensure the protection of personal data and other information of Users from unauthorized or accidental access to them, destruction, modification, blocking, copying, distribution, as well as from other illegal actions.
                <br/><br/>
                Users have the right to contact the Internet resource Administration on issues related to the use of the Internet resource and its services, as well as on issues related to the deletion of the information provided before the expiration of the period of its storage, processing and use. The procedure and terms for considering Users' requests are set out in the relevant section of the Internet resource.
            </span>}
    },
}