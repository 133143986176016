const initialState = {
    chess: null,
    data: "",
    successMessage: null
}

const restChessReducer = (state = initialState, action) => {
    switch (action.type){ 
        case 'GET_CHESS_SUCCESS':
            return {
                ...state,
                chess: action.payload
            }
        case 'SEND_DATA':
            return {
                ...state,
                data: action.payload
            }
        case 'SEND_DATA_SUCCESS':
            console.log(action.payload)
            return {
                ...state,
                successMessage: action.payload
            }
        case 'CHANGE_MESSAGE_STATUS':
        return {
            ...state,
            successMessage: null
        }
        default: 
        return state;  
    }
}

export default restChessReducer

